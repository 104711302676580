import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { NotificationDto } from '../models/notification';
import { mergeUniqueById } from '../utils/mergeUniqueById';

interface NotificationState {
  data: NotificationDto[];
  totalUnread: number;
}

const initialState: NotificationState = {
  data: [],
  totalUnread: 0
};

const slice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    setNotificationList(
      state: NotificationState,
      action: PayloadAction<NotificationDto[]>
    ) {
      state.data = mergeUniqueById(state.data, action.payload);
    },
    addNewNotification(
      state: NotificationState,
      action: PayloadAction<NotificationDto>
    ) {
      state.data = [action.payload, ...state.data];
    },
    setTotalUnread: (state, action: PayloadAction<number>) => {
      state.totalUnread = action.payload;
    },
    updateNotificationItem(
      state: NotificationState,
      action: PayloadAction<NotificationDto>
    ) {
      state.data = state.data.map((notification) =>
        notification.id === action.payload.id
          ? { ...notification, ...action.payload }
          : notification
      );
    }
  }
});

export const notificationsReducer = slice.reducer;

export const {
  setNotificationList,
  updateNotificationItem,
  setTotalUnread,
  addNewNotification
} = slice.actions;

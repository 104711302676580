import { useEffect } from 'react';
import * as signalR from '@microsoft/signalr';
import { useDispatch } from '../store';
import { addNewNotification, setTotalUnread } from '../slices/notifications';
import { NotificationDto } from '../models/notification';
import axios from '../utils/axios';

export const useSignal = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const connection = new signalR.HubConnectionBuilder()
      .withUrl(`${process.env.REACT_APP_API_URL}/notificationHub`) // Укажите URL SignalR-хаба
      .withAutomaticReconnect() // Автоматическое переподключение
      .configureLogging(signalR.LogLevel.Information)
      .build();

    connection
      .start()
      .then(() => console.log('SignalR Connected'))
      .catch((err) => console.error('Connection failed: ', err));

    connection.on('ReceiveNotification', (notificationType, message) => {
      console.log('New message:', JSON.parse(message));
      console.log('Τype:', notificationType);

      const notification: any = JSON.parse(message);

      axios
        .post('/notifications/list', {
          limit: 1,
          offset: 0
        })
        .then((res) => {
          dispatch(setTotalUnread(res.data.totalUnread));
        });

      dispatch(
        addNewNotification({
          id: notification.Id,
          status: notification.Status,
          message: notification.Message,
          url: notification.Url,
          createdAt: notification.CreatedAt
        } as NotificationDto)
      );
    });

    return () => {
      connection.stop().then(() => console.log('SignalR Disconnected'));
    };
  }, []);
};

import {
  alpha,
  Avatar,
  Badge,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  Popover,
  styled,
  Tooltip,
  Typography
} from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import NotificationsActiveTwoToneIcon from '@mui/icons-material/NotificationsActiveTwoTone';
import { useTranslation } from 'react-i18next';
import { formatDistance } from 'date-fns';
import { useDispatch, useSelector } from '../../../../../store';
import { FeedbackTwoTone } from '@mui/icons-material';
import axios from '../../../../../utils/axios';
import {
  setNotificationList,
  setTotalUnread,
  updateNotificationItem
} from '../../../../../slices/notifications';
import { Link } from 'react-router-dom';
import CheckIcon from '@mui/icons-material/Check';
import { NotificationDto } from '../../../../../models/notification';

const LIMIT = 10;

function HeaderNotifications() {
  const { data, totalUnread } = useSelector((state) => state.notifications);
  const dispatch = useDispatch();
  const ref = useRef<any>(null);

  const [isOpen, setOpen] = useState<boolean>(false);
  const [isFullHeight, setFullHeight] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(false);
  const [isLastPage, setLastPage] = useState<boolean>(false);

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  // Обновить счетчик непрочитанных уведомлений
  useEffect(() => {
    if (!isOpen) {
      axios
        .post('/notifications/list', {
          limit: 1,
          offset: 0
        })
        .then((res) => {
          dispatch(setTotalUnread(res.data.totalUnread));
        });
    }
  }, [isOpen]);

  useEffect(() => {
    const loadNotifications = async (page: number) => {
      setLoading(true);
      try {
        const res = await axios.post('/notifications/list', {
          limit: LIMIT,
          offset: (page - 1) * LIMIT
        });

        if (res.data.items.length < LIMIT) {
          setLastPage(true);
        }

        dispatch(setNotificationList(res.data.items));
      } finally {
        setLoading(false);
      }
    };

    if (!isLastPage) {
      loadNotifications(page);
    }
  }, [page, isLastPage]);

  const handleCheckboxChange = async (notification: NotificationDto) => {
    const updatedNotification: NotificationDto = {
      ...notification,
      status: notification.status === 'read' ? 'notRead' : 'read'
    };

    try {
      dispatch(updateNotificationItem(updatedNotification));

      await axios.put(`/notifications/${notification.id}`, updatedNotification);
    } catch (error) {
      console.error('Failed to update notification', error);
    }
  };

  const { t }: { t: any } = useTranslation();

  return (
    <>
      <Tooltip arrow title={t('Notifications')}>
        <IconButtonWrapper color="primary" ref={ref} onClick={handleOpen}>
          <NotificationsBadge
            badgeContent={totalUnread}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
          >
            <NotificationsActiveTwoToneIcon />
          </NotificationsBadge>
        </IconButtonWrapper>
      </Tooltip>
      <Popover
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        PaperProps={{
          sx: {
            bottom: isFullHeight ? '16px' : 'auto',
            minWidth: '400px',
            display: 'flex',
            flexDirection: 'column'
          }
        }}
      >
        <Box
          sx={{
            padding: '16px 12px 16px 16px',
            display: 'flex',
            alignItems: 'center',
            borderBottom: '1px solid #0000000D',
            gap: '10px'
          }}
          display="flex"
          justifyContent="center"
        >
          <Typography variant="h5">{t('Notifications')}</Typography>
        </Box>
        <List
          sx={{
            p: 2,
            flexGrow: 1,
            maxHeight: isFullHeight ? 'auto' : '410px',
            overflowY: 'auto'
          }}
          onScroll={(e: any) => {
            const bottom =
              e.target.scrollHeight - e.target.scrollTop ===
              e.target.clientHeight;
            if (bottom && !loading) {
              setPage((prevPage) => prevPage + 1);
            }
          }}
        >
          {data.length === 0 && (
            <ListItem>
              <Typography
                sx={{ margin: 'auto' }}
                variant="body2"
                color="text.secondary"
              >
                {t('No new notifications')}
              </Typography>
            </ListItem>
          )}

          {data.map((notification, index) => {
            return (
              <ListItem
                key={notification.id}
                sx={{
                  display: { xs: 'block', sm: 'flex' },
                  minWidth: '400px'
                }}
              >
                <CustomCheckbox
                  checked={notification.status === 'read'}
                  onChange={(e) => handleCheckboxChange(notification)}
                  icon={
                    <div
                      style={{ width: 36, height: 36, borderRadius: '50%' }}
                    />
                  } // Пустой div вместо квадрата
                  checkedIcon={
                    <div
                      style={{
                        width: 24,
                        height: 24,
                        borderRadius: '50%',
                        backgroundColor: '#4CAF50',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                      }}
                    >
                      <CheckIcon style={{ color: 'white' }} />
                    </div>
                  }
                />
                <ListItemAvatar
                  sx={{
                    mb: { xs: 1, sm: 0 }
                  }}
                >
                  <Avatar
                    sx={{
                      height: '48px',
                      width: '48px',
                      background: '#ECFBE6'
                    }}
                  >
                    <FeedbackTwoTone sx={{ fill: '#3FBF02' }} />
                  </Avatar>
                </ListItemAvatar>
                <Box flex={1}>
                  <Box display="flex" justifyContent="space-between">
                    <Typography
                      sx={{
                        fontWeight: 'bold'
                      }}
                    >
                      TLV System
                    </Typography>
                    <Typography
                      variant="caption"
                      sx={{
                        textTransform: 'none'
                      }}
                    >
                      {formatDistance(
                        new Date(notification.createdAt),
                        new Date(),
                        {
                          addSuffix: true
                        }
                      )}
                    </Typography>
                  </Box>
                  <Typography
                    component="p"
                    variant="body2"
                    color="text.secondary"
                    sx={{
                      maxWidth: '300px'
                    }}
                  >
                    {notification.message}
                  </Typography>

                  {notification.url.startsWith('/') ? (
                    <Link to={notification.url} target={'_blank'}>
                      open resource link
                    </Link>
                  ) : (
                    <a
                      href={notification.url}
                      target={'_blank'}
                      rel="noreferrer"
                    >
                      open resource link
                    </a>
                  )}
                </Box>
              </ListItem>
            );
          })}
        </List>

        {loading && (
          <Box
            sx={{
              position: 'absolute',
              bottom: '18px',
              left: 0,
              right: 0
            }}
            display="flex"
            justifyContent="center"
            p={2}
          >
            <CircularProgress size={24} />
          </Box>
        )}

        <Divider />
        <Box m={1}>
          {isFullHeight ? (
            <Button
              color="secondary"
              fullWidth
              onClick={() => {
                setFullHeight(false);
              }}
            >
              {t('View less notifications')}
            </Button>
          ) : (
            <Button
              color="secondary"
              fullWidth
              onClick={() => {
                setFullHeight(true);
              }}
            >
              {t('View more notifications')}
            </Button>
          )}
        </Box>
      </Popover>
    </>
  );
}

const AnimatedBadge = styled(Badge)(
  ({ theme }) => `
    
    .MuiBadge-badge {
        box-shadow: 0 0 0 2px ${theme.palette.background.paper};
        background-color: #44b700;
        color: #44b700;
        
        &::after {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            animation: ripple 1.2s infinite ease-in-out;
            border: 1px solid currentColor;
            content: "";
        }
    }
`
);

const NotificationsBadge = styled(Badge)(
  ({ theme }) => `
    
    .MuiBadge-badge {
        background-color: ${alpha(theme.palette.error.main, 0.1)};
        color: ${theme.palette.error.main};
        min-width: 16px; 
        height: 16px;
        padding: 0;

        &::after {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            box-shadow: 0 0 0 1px ${alpha(theme.palette.error.main, 0.3)};
            content: "";
        }
    }
`
);

const IconButtonWrapper = styled(IconButton)(
  ({ theme }) => `
        width: ${theme.spacing(7)};
        height: ${theme.spacing(7)};
`
);

const CustomCheckbox = styled(Checkbox)({
  '&.MuiCheckbox-root': {
    borderRadius: '50%',
    border: '1px solid #FF7B00', // Оранжевая обводка
    width: 24,
    height: 24,
    marginRight: '16px'
  },
  '&.Mui-checked': {
    backgroundColor: '#3FBF02', // Зелёный фон при активации
    border: 'none'
  },
  '&.Mui-checked .MuiSvgIcon-root': {
    color: 'white' // Цвет галочки
  }
});

export default HeaderNotifications;
